import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Grid, List, ListItem, Paper, Typography } from "@material-ui/core"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Typography variant="h2" component="h1" gutterBottom={true}>
      Projects
    </Typography>
    <Paper elevation={3}>
      <Grid container>
        <Grid item xs={12}>
          <List>
            <ListItem button component={Link} to="/sequencer">
              Sequencer
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Paper>
  </Layout>
)

export default IndexPage
